import React from "react";
import PropTypes from "prop-types";
import "./subContent.css";
import { Container, Row, Col } from "react-bootstrap";

// assets
import Who from "./../assets/who-img.png";
import lotusAppScreenshot from "./../assets/lotus-app-screenshot.3b60fa6b.png";
import hologram1 from "./../assets/hologram1.png";
import hologram2 from "./../assets/hologram2.png";
import hologram3 from "./../assets/hologram3.png";

const subContent = (props) => {

  return (
    <Container>
      <Row>
        <Col style={{ textAlign: "center",marginBottom: "5vh" }}>
          <img className="who-img" src={Who} />
        </Col>
       
      </Row>
      <Row>

        <Col xs={12} md={8} lg={8} className='text-general'>
          <span className='focus-text'>ครั้งแรกของวงการรีเทล!</span> โลตัสแจก <span className='focus-text'>my Lotus’s NFT Generative Arts</span> ของพรีเมี่ยมสุดล้ำ 1,500 ชิ้น ไม่ซ้ำใครในโลก ในคอนเซ็ปต์ <span className='focus-text'>“my Smart Delight World”</span> กลุ่มคนจากโลกอนาคต ที่มีความหลากหลาย ชื่นชอบเทคโนโลยีที่ทันสมัย ล้ำยุค และเป็นตัวของตัวเอง มายโลตัสให้คุณได้ครอบครองของขวัญสุดสมาร์ท กับ <span className='focus-text'>my Lotus’s NFT</span> ตอกย้ำความสมาร์ทของระบบสมาชิกใหม่ <span className='focus-text'>“มายโลตัส”</span> ระบบสมาชิกใหม่บน <span className='focus-text'>“โลตัส สมาร์ท แอป”</span> ที่ง่าย ทันใจ มีสิทธิพิเศษรู้ใจเฉพาะคุณ
        </Col>

        <Col xs={12} md={4} lg={4} style={{ textAlign: "center" }}>
          <img className="app-screenshort-img" src={lotusAppScreenshot} />
        </Col>
       
      </Row>

      <Row style={{marginTop: "5vh",marginBottom: "5vh"}}>
        <Col xs={12} md={4} lg={4}>
          <Row>
            <Col style={{ textAlign: "center"}} xs={4} md={4} lg={4}>
              <img className="hologram-img" src={hologram1} />
            </Col>
            <Col xs={8} md={8} lg={8} className="who-sub">
              <span className='focus-text'>“Smart”</span>
              <br></br>
              <span className="text-general">ฉลองระบบสมาชิกใหม่ “มายโลตัส” ด้วยการสร้างสรรค์ผลงานสุดสมาร์ท <span style={{ color: "var(--lotus-yellow)"}}>my Lotus’s NFT </span></span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <Row>
            <Col style={{ textAlign: "center"}} xs={4} md={4} lg={4}>
              <img className="hologram-img" src={hologram2} />
            </Col>
            <Col xs={8} md={8} lg={8} className="who-sub">
              <span className='focus-text'>“Coin”</span>
              <br></br>
              <span className="text-general">แรงบันดาลใจจากเหรียญโลตัสคอยน์ ในระบบสมาชิก “มายโลตัส” ที่คุ้มค่า และสมาร์ท ในทุกการใช้งาน</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4} lg={4}>
          <Row>
            <Col style={{ textAlign: "center"}} xs={4} md={4} lg={4}>
              <img className="hologram-img" src={hologram3} />
            </Col>
            <Col xs={8} md={8} lg={8} className="who-sub">
              <span className='focus-text'>“my”</span>
              <br></br>
              <span className="text-general">คาแรคเตอร์มีเพียง 1 ชิ้นในโลกเท่านั้น ตอบโจทย์ความรู้ใจคุณ ไม่ซ้ำใคร</span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

subContent.propTypes = {};

export default subContent;
