import "regenerator-runtime/runtime";
import React, { useEffect, useState } from "react";

// React Bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";

// React Bootstraps imports
import { Nav, Navbar, Container, Row, Col, Card, Alert } from "react-bootstrap";

// Custom Components
import NavComponents from "./Components/navComponents"
import BrandComponents from "./Components/brandComponents"
// import ConnectWallet from "./Components/connectWallet"
import SubContent from "./Components/subContent"
import PreparingContent from "./Components/preparingContent"
import NftExample from "./Components/nftExample"
import TextContent from "./Components/textContent"
import getConfig from "./config";
import axios from 'axios';
//axios.defaults.baseURL = 'http://localhost:5000'; //delete on prd

const { networkId,contractName } = getConfig();

export default function App() {
  console.warn(contractName,networkId);
  const deviceDetect = () => {
      const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
  }

  if(deviceDetect()){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  return (
    <React.Fragment>
      {" "}
      <NavComponents />
      <BrandComponents />
      <Container style={{ marginTop: "3vh" , color:"red" , textAlign:"center" }}>
      <Row>
      <Col>
      ขณะนี้ NFT มีการกรอกโค้ดเพื่อรับหมดครบ 1,500 ชิ้นเรียบร้อยแล้ว จากระบบ First Come First Serve หรือทำรายการก่อน ได้รับก่อน สอบถามข้อมูลเพิ่มเติม หรือติดตามกิจกรรมเพิ่มเติมกับทีมงานได้ทาง Discord <a href="https://discord.gg/GPUEkzR4d3" className="connect-wallet-link" target="_blank">https://discord.gg/GPUEkzR4d3</a>
      </Col>
      </Row>
      </Container>
      <SubContent />
      <PreparingContent />
      <TextContent />
      <NftExample />
      {" "}
      <NavComponents />
    </React.Fragment>
  );

}
