import React from "react";
import PropTypes from "prop-types";
import "./brandComponents.css";
import { Container, Row, Col } from "react-bootstrap";

// assets
import Logo from "./../assets/logo-center.5e5a499b.png";
import nftExBg from "./../assets/nft-ex-bg.png";
import NftMain from "./../assets/nft-main.png";
import NftLeft1 from "./../assets/nft-left-1.png";
import NftLeft2 from "./../assets/nft-left-2.png";
import NftRight1 from "./../assets/nft-right-1.png";
import NftRight2 from "./../assets/nft-right-2.png";
import NftRight3 from "./../assets/nft-right-3.png";

const brandComponents = (props) => {

  return (
    <Container fluid className='brand-bg' style={{ backgroundImage: `url(${nftExBg})` }}>
      
      <Row>
        <Col  className='sub-layout'>
          <img className="nft-sub nft-left-1" src={NftLeft1} />
          <img className="nft-sub nft-left-2" src={NftLeft2} />
        </Col>
        <Col className='brand-layout'>
          <img className="brand-icon" src={Logo} />
          <img className="nft-main" src={NftMain} />
        </Col>
        <Col className='sub-layout'>
          <img className="nft-sub-right nft-right-1" src={NftRight1} />
          <img className="nft-sub-right nft-right-2" src={NftRight2} />
          <img className="nft-sub-right nft-right-3" src={NftRight3} />
        </Col>
        <div className="blur-gradient"></div>
      </Row>
    </Container>
  );
};

brandComponents.propTypes = {};

export default brandComponents;
