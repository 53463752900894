import React from "react";
import PropTypes from "prop-types";
import "./navComponents.css";
import { Nav, Navbar, Container } from "react-bootstrap";

// assets
import NavLogoLotus from "./../assets/logo-nav.png"
import discordIcon from "./../assets/discord-icon.png"
import twitterIcon from "./../assets/twitter-icon.png"

const navComponents = (props) => {

  const twitterLink = async() => {
    window.open('https://twitter.com/TaiternNFT', '_blank');
  }

  const discordLink = async() => {
    window.open('https://discord.gg/tag5zsbUpy', '_blank');
  }

  return (
    <Navbar className='navbar-lotus' expand="lg">
        <Container fluid>
          <Navbar.Brand href='#home'>
            <img
              alt='lotus-logo'
              src={NavLogoLotus}
              className='lotus-logo-nav'
            />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='basic-navbar-nav' />

          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
             
                <Nav.Item className='nav-font'>
                  <Nav.Link href="#nftExample" eventKey="#nftExample" style={{ textAlign: "center"}}>
                    ตัวอย่าง NFT
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='nav-font'>
                  <Nav.Link href="#howToOpenWallet" eventKey="#howToOpenWallet" style={{ textAlign: "center"}}>
                    วิธีสมัคร NEAR Wallet
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='nav-font'>
                  <Nav.Link href="#howToMints" eventKey="#howToMints" style={{ textAlign: "center"}}>
                    วิธีรับ my Lotus’s NFT
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className='nav-font'>
                  <Nav.Link href="#FAQ" eventKey="#FAQ" style={{ textAlign: "center"}}>
                    คำถามที่พบบ่อย
                  </Nav.Link>
                </Nav.Item>
               
            </Nav>

            <Nav>
              <Nav.Item onClick={discordLink} style={{ textAlign: "center"}}>
                <img className="nav-social-media-icon" src={discordIcon} />
              </Nav.Item>
              <Nav.Item onClick={twitterLink} style={{ textAlign: "center"}}>
                <img className="nav-social-media-icon" src={twitterIcon} />
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
  );
};

navComponents.propTypes = {};

export default navComponents;
