import React from "react";
import PropTypes from "prop-types";
import "./textContent.css";
import { Container, Row, Col } from "react-bootstrap";
import CollapseItem from './collapseItem';
import Articles from './articles';
// assets
import plusIcon from "./../assets/plus.png";
import crossIcon from "./../assets/cross.png";

const textContent = (props) => {

  let articles = [];
  for (const key in Articles) {
    if((typeof Articles[key].data != 'undefined')&&(Articles[key].data != '')){
      articles.push(
        <CollapseItem key={key} title={key} id={Articles[key].id}>
          {Articles[key].data}
        </CollapseItem>
      );
    }
  }

  return (
    <Container fluid className="secondary-bg">
      <Row>
        <Col>
          <Container style={{ marginTop: "5vh" , marginBottom: "10vh" }}>
            <Row>
              <Col>
                {articles}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

textContent.propTypes = {};

export default textContent;
