import React from "react";
import PropTypes from "prop-types";
import { Collapse } from 'react-collapse';
import { Container, Row, Col } from "react-bootstrap";
import "./collapseItem.css";
import parse from 'html-react-parser'

// assets
import plusIcon from "./../assets/plus.png";
import crossIcon from "./../assets/cross.png";

const collapseItem = (props) => {

	const [isDropdownOpen,setDropdownOpen] = React.useState(false);
	const [dropdownToggleIcon,setDropdownToggleIcon] = React.useState(plusIcon);

	const onDropdownClicked = () => {
	 setDropdownOpen(!isDropdownOpen);
	 if(isDropdownOpen){ setDropdownToggleIcon(plusIcon) }else{ setDropdownToggleIcon(crossIcon) }
	};

	return (
		<Row id={props.id} className="drop-down-layout">
	        
	        <Col xs={2} md={2} lg={2} className="drop-down-icon-layout" onClick={onDropdownClicked}>
	            <img className="drop-down-icon" src={dropdownToggleIcon} />
	        </Col>
	        <Col xs={8} md={8} lg={8} style={{ textAlign: "center" , cursor: "pointer" }} onClick={onDropdownClicked}>
	          <div className="drop-down-title">{props.title}</div>
	        </Col>
	        <Col xs={2} md={2} lg={2} style={{ cursor: "pointer" }} onClick={onDropdownClicked}></Col>
	        <Collapse isOpened={isDropdownOpen}>
	          <div className="text-general" style={{ marginTop: "5vh" }}>{parse(props.children)}</div>
	        </Collapse>

	     </Row>
	);
};

collapseItem.propTypes = {};

export default collapseItem;