import React from "react";
import PropTypes from "prop-types";
import "./preparingContent.css";
import { Container, Row, Col } from "react-bootstrap";

// assets
import Who from "./../assets/who-img.png";
import preparingImg from "./../assets/preparing.daf1a609.png";
import preparingHologram1 from "./../assets/preparing-hologram-1.png";
import preparingHologram2 from "./../assets/preparing-hologram-2.png";
import qrCode from "./../assets/qr.ba0275f5.a2e19c0a.png";
import appStoreIcon from "./../assets/app-store-icon.png";
import googlePlayIcon from "./../assets/google-play-icon.png";
import nearRegister from "./../assets/near-register.png";
import nearHowto from "./../assets/near-howto.png";

const preparingContent = (props) => {

  const nearLink = async() => {
    window.open('https://wallet.near.org/', '_blank');
  }

  return (
    <Container fluid className="secondary-bg">
      <Row>
      <Col>
      <Container style={{ marginTop: "5vh" }}>
      <Row>
        <Col style={{ textAlign: "center",marginBottom: "5vh" }}>
          <img className="preparing-img" src={preparingImg} />
        </Col>
      </Row>

      <Row>

        <Col xs={12} md={6} lg={6}>
          <Row>
            <Col xs={6} md={4} lg={4} style={{ alignItems: "flex-end",display: "flex" }} className="col-preparing">
              <img className="preparing-hologram" src={preparingHologram1} />
            </Col>
            <Col xs={6} md={8} lg={8}>
              <Row>
                <Col className="preparing-text">
                  <span className="preparing-header">1. ดาวน์โหลด <br></br>Lotus’s Smart App</span>
                  <br></br>
                  และสมัครสมาชิกมายโลตัส เพื่อรับ Ticket Code ในแอปพลิเคชัน
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "center", paddingRight: "0.2vw"}}>
                  <img className="qr-code" src={qrCode} />
                </Col>

                <Col style={{ textAlign: "center", paddingLeft: "0.2vw"}}>
                  <img className="preparing-link app-store" src={appStoreIcon} />
                  <img className="preparing-link google-play" src={googlePlayIcon} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} lg={6}>
          <Row>
            <Col xs={6} md={4} lg={4} style={{ alignItems: "flex-end",display: "flex" }} className="col-preparing">
              <img className="preparing-hologram" src={preparingHologram2} />
            </Col>
            <Col xs={6} md={8} lg={8}>
              <Row>
                <Col className="preparing-text">
                  <span className="preparing-header">2. เปิดกระเป๋า NEAR Wallet</span>
                  <br></br>
                  สมัคร NEAR Wallet เพื่อใช้เป็นกระเป๋าดิจิตัล สำหรับเก็บ NFT (ต้องเตรียม 0.2 NEAR สำหรับการ verify กระเป๋า)
                </Col>
              </Row>
              <Row>
                <Col style={{ textAlign: "center"}}>
                <img className="preparing-link near-register" src={nearRegister} onClick={nearLink} />
                <a href="#howToOpenWallet"><img className="preparing-link near-howto" src={nearHowto} /></a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

      </Row>
    
    
      </Container>
      </Col>
      </Row>
    </Container>
  );
};

preparingContent.propTypes = {};

export default preparingContent;
