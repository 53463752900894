import React from "react";
import PropTypes from "prop-types";
import "./nftExample.css";
import { Container, Row, Col } from "react-bootstrap";

// assets
import nftExBg from "./../assets/nft-ex-bg.png";
import nftEx from "./../assets/nft-ex.png";
// sample nft image
import sample1 from "./../assets/sample_NFT/sample1.jpg";
import sample2 from "./../assets/sample_NFT/sample2.jpg";
import sample3 from "./../assets/sample_NFT/sample3.jpg";
import sample4 from "./../assets/sample_NFT/sample4.jpg";
import sample5 from "./../assets/sample_NFT/sample5.jpg";
import sample6 from "./../assets/sample_NFT/sample6.jpg";
import sample7 from "./../assets/sample_NFT/sample7.jpg";
import sample8 from "./../assets/sample_NFT/sample8.jpg";
import sample9 from "./../assets/sample_NFT/sample9.jpg";
import sample10 from "./../assets/sample_NFT/sample10.jpg";
import sample11 from "./../assets/sample_NFT/sample11.jpg";
import sample12 from "./../assets/sample_NFT/sample12.jpg";
import sample13 from "./../assets/sample_NFT/sample13.jpg";
import sample14 from "./../assets/sample_NFT/sample14.jpg";
import sample15 from "./../assets/sample_NFT/sample15.jpg";
import sample16 from "./../assets/sample_NFT/sample16.jpg";
import sample17 from "./../assets/sample_NFT/sample17.jpg";
import sample18 from "./../assets/sample_NFT/sample18.jpg";
import sample19 from "./../assets/sample_NFT/sample19.jpg";
import sample20 from "./../assets/sample_NFT/sample20.jpg";
import sample21 from "./../assets/sample_NFT/sample21.jpg";
import sample22 from "./../assets/sample_NFT/sample22.jpg";
import sample23 from "./../assets/sample_NFT/sample23.jpg";
import sample24 from "./../assets/sample_NFT/sample24.jpg";
import sample25 from "./../assets/sample_NFT/sample25.jpg";
import sample26 from "./../assets/sample_NFT/sample26.jpg";
import sample27 from "./../assets/sample_NFT/sample27.jpg";
import sample28 from "./../assets/sample_NFT/sample28.jpg";
import sample29 from "./../assets/sample_NFT/sample29.jpg";
import sample30 from "./../assets/sample_NFT/sample30.jpg";

const nftExample = (props) => {

let imageList = [sample1,sample2,sample3,sample4,sample5,sample6,sample7,sample8,sample9,sample10,sample11,sample12,sample13,sample14,sample15,sample16,sample17,sample18,sample19,sample20,sample21,sample22,sample23,sample24,sample25,sample26,sample27,sample28,sample29,sample30];

  let randomList = [];
  let imageCard = [];
  while(randomList.length < 12){
      var r = Math.floor(Math.random() * 30) + 1;
      if(randomList.indexOf(r) === -1){
        randomList.push(r);
        imageCard.push(<Col className="nftexample-fream" key={r}><a href="https://paras.id/collection/taiternx.near" target="_blank"><img className="nftexample-img" src={imageList[(r-1)]} /></a></Col>);
      }
  }

  return (
    <Container fluid className='nftexample-bg' style={{ backgroundImage: `url(${nftExBg})` }} id="nftExample" >
      <Row>
        <Col style={{ textAlign: "center",marginTop: "5vh",marginBottom: "5vh" }}>
          <img className="nft-ex-header" src={nftEx} />
        </Col>
      </Row>
      <Row>
        <Col xs={1} md={1} lg={1}></Col>
        <Col xs={10} md={10} lg={10}>
          <Container className="nft-ex-layout">
          <Row xs={12} md={4} lg={4}>
            {imageCard}
          </Row>
          </Container>
        </Col>
        <Col xs={1} md={1} lg={1}></Col>
      </Row>
    </Container>
  );
};

nftExample.propTypes = {};

export default nftExample;
